import React from 'react';
import { FormattedMessage } from 'react-intl';

import Link from '/src/components/LocalizedLink';
// import IconWhatsApp from '../../assets/images/svg/whatsapp.inline.svg';
import Layout from '../../components/Layout';
import IconArrowRight from '../../assets/images/svg/arrow-right.inline.svg';
import imgBlockR from '../../assets/images/accreditation/Photo 1 (3).png';
import imgBlockL from '../../assets/images/accreditation/Photo 2 (4).png';
import imgDarkR from '../../assets/images/accreditation/Photo -1.png';
import imgDarkL from '../../assets/images/accreditation/Photo 4.png';
import iconTrinity from '../../assets/images/accreditation/1Trinity logo no background.png';
import iconSLB from '../../assets/images/accreditation/1CSLB-logo-400x126.png';
import iconA from '../../assets/images/accreditation/A-logo.png';
import iconL4L from '../../assets/images/accreditation/Languages4Life logo.png';
import iconIatefl from '../../assets/images/accreditation/I-logo.png';
import iconTick from '../../assets/images/svg/tick.svg';

import '../../assets/styles/pages/accreditation-page.scss';

const Accreditation = () => {
  return (
    <Layout
      title={{ id: 'accreditation.seo.title' }}
      description={{ id: 'accreditation.seo.description' }}
    >
      <div className="accreditation-page">
        <section className="first-section">
          <div className="container">
            <h1 className="c-title-42">
              <FormattedMessage id="accreditation.sec1.title" />
            </h1>
          </div>
        </section>
        <section className="second-section c-section-py">
          <div className="container">
            <div className="row top">
              <div className="col-12 col-md-6">
                <div className="block_l">
                  <h2 className="c-title-34 block__title">
                    <FormattedMessage id="accreditation.sec2.title" />
                  </h2>
                  <p className="c-text-18 block__subtitle">
                    <FormattedMessage id="accreditation.sec2.description" />
                  </p>
                  <p className="c-text-16 block__description">
                    <FormattedMessage id="accreditation.sec2.text" />
                  </p>
                </div>
              </div>
              <div className="col-12 col-md-6">
                <div className="block_r">
                  <img src={imgBlockR} alt="" className="block_r__img" />
                  <div className="block__logo block_r__trinity">
                    <img src={iconTrinity} alt="iconTrinity" />
                  </div>
                </div>
              </div>
            </div>
            <div className="row bot">
              <div className="col-12 col-md-6">
                <div className="block_l--bot">
                  <img className="block_l--bot__img" src={imgBlockL} alt="imgBlockL" />
                  <div className="block__logo block_l--bot__SLB">
                    <img src={iconSLB} alt="iconSLB" />
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-6">
                <div className="block_r--bot">
                  <h2 className="c-title-34 block__title">
                    <FormattedMessage id="accreditation.sec2.title2" />
                  </h2>
                  <p className="c-text-18 block__subtitle">
                    <FormattedMessage id="accreditation.sec2.description2" />
                  </p>
                  <div className="c-benefit-list">
                    <div className="benefit">
                      <img src={iconTick} alt="iconTick" />
                      <p className="c-text-16">
                        <FormattedMessage id="accreditation.sec2.ben1" />
                      </p>
                    </div>{' '}
                    <div className="benefit">
                      <img src={iconTick} alt="iconTick" />
                      <p className="c-text-16">
                        <FormattedMessage id="accreditation.sec2.ben2" />
                      </p>
                    </div>{' '}
                    <div className="benefit">
                      <img src={iconTick} alt="iconTick" />
                      <p className="c-text-16">
                        <FormattedMessage id="accreditation.sec2.ben3" />
                      </p>
                    </div>{' '}
                    <div className="benefit">
                      <img src={iconTick} alt="iconTick" />
                      <p className="c-text-16">
                        <FormattedMessage id="accreditation.sec2.ben4" />
                      </p>
                    </div>{' '}
                    <div className="benefit">
                      <img src={iconTick} alt="iconTick" />
                      <p className="c-text-16">
                        <FormattedMessage id="accreditation.sec2.ben5" />
                      </p>
                    </div>{' '}
                    <div className="benefit">
                      <img src={iconTick} alt="iconTick" />
                      <p className="c-text-16">
                        <FormattedMessage id="accreditation.sec2.ben6" />
                      </p>
                    </div>
                  </div>
                  <p className="c-text-18 block__description">
                    <FormattedMessage id="accreditation.sec2.text2" />
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="third-section c-section-py">
          <div className="container">
            <div className="row">
              <div className="col-md-6 col-12">
                <div className="block_l">
                  <h2 className="c-title-34 block_l__title">
                    <FormattedMessage id="accreditation.sec3.title" />
                  </h2>
                  <p className="c-text-18">
                    <FormattedMessage id="accreditation.sec3.description" />
                  </p>
                </div>
              </div>
              <div className="col-md-6 col-12">
                <div className="block_r">
                  <div className="block_r__title">
                    <a target="_blank" href="https://languages4life.com/en/">
                      <FormattedMessage id="accreditation.sec3.sc1.title" />
                    </a>
                  </div>
                  <p className="c-text-14 block_r__par">
                    <FormattedMessage id="accreditation.sec3.sc1.text" />
                  </p>
                  <div className="block_r__title">
                    <FormattedMessage id="accreditation.sec3.sc2.title" />{' '}
                  </div>
                  <p className="c-text-14 block_r__par">
                    {/* This is not an accreditation but simply good practice – TEFL Iberia is a
                    contributing member of IATEFL (International Association of Teachers of a
                    Foreign Language). Their main purpose is to link, develop and support English
                    language teaching professionals around the world. As an institutional member we
                    benefit from regular publications and remain up-to-date with regular
                    developments in English language teaching.{' '}
                    <a target="_blank" href="https://www.iatefl.org/" className="red-link">
                      IATEFL Website.
                    </a> */}
                    <FormattedMessage
                      id="accreditation.sec3.sc2.text"
                      values={{
                        accentedLink: (chunk) => (
                          <a target="_blank" href="https://www.iatefl.org/" className="red-link">
                            {chunk}
                          </a>
                        ),
                      }}
                    />
                  </p>
                  <div className="block_r__wrapper">
                    <div className="item">
                      <img src={iconA} alt="iconA" />
                    </div>
                    <div className="item">
                      <img src={iconL4L} alt="L4L" />
                    </div>
                    <div className="item">
                      <img src={iconIatefl} alt="Iatefl" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="fourth-section c-section-py">
          <div className="container">
            <div className="row">
              <div className="col-12 col-md-6">
                <div className="block_l">
                  <div className="block_l__img--l">
                    <img src={imgDarkR} alt="imgDarkL" />
                  </div>
                  <div className="block_l__img--r">
                    <img src={imgDarkL} alt="imgDarkR" />
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-6">
                <div className="block_r">
                  <h2 className="c-title-34 block_r__title">
                    <FormattedMessage id="accreditation.sec4.title" />
                  </h2>
                  <p className="c-text-18 block_r__subtitle">
                    <FormattedMessage id="accreditation.sec4.description" />
                  </p>
                  <p className="c-text-16 block_r__description">
                    <FormattedMessage id="accreditation.sec4.text" />
                  </p>
                  <FormattedMessage id="url.apply.index">
                    {(locale) => (
                      <Link to={locale[0]} className="c-btn c-btn--red">
                        <FormattedMessage id="menu.applyNow" />
                        <IconArrowRight />
                      </Link>
                    )}
                  </FormattedMessage>
                  {/* <Link to="/apply/" className="c-btn c-btn--red">
                    Apply now
                    <IconArrowRight />
                  </Link> */}
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </Layout>
  );
};

export default Accreditation;
